/* unplugin-vue-components disabled */import __unplugin_components_4 from 'C:/docker/118_meijia/frontend/src/components/BaseSpacer.vue';
import __unplugin_components_3 from 'C:/docker/118_meijia/frontend/src/components/BRow.vue';
import __unplugin_components_2 from 'C:/docker/118_meijia/frontend/src/components/BaseButton.vue';
import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/Tabbar.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MSelect.vue';
import "core-js/modules/es.function.name.js";
import { unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "col-auto"
};
var _hoisted_2 = {
  class: "col-auto"
};
var _hoisted_3 = {
  class: "col text-align-right"
};
import { useForm } from "vee-validate";
export default {
  __name: 'DataCard',
  setup: function setup(__props) {
    var router = useRouter();
    var store = useStore();

    var query = _.defaults(router.currentRoute.value.query, {
      id: ''
    });

    var inIframe = top != self;
    var isCome = computed(function () {
      return store.getters['auth/isCome'];
    });
    var cData = reactive({
      pageReady: false,
      basicInfo: '',
      questionnaire: '',
      tabbarActiveItem: 0,
      tabbarActiveId: computed(function () {
        return cData.tabbarActiveItem || cData.tabbarActiveItem.id;
      }),
      tabbarList: [
      /**
       * top != self 提供後台操作用客戶資料卡
       * 後台使用iframe載入本頁面
       */
      {
        id: 0,
        text: '基本資料',
        path: top == self ? "/customer/data-card/basic-info?id=".concat(query.id) : "/data-card/basic-info?id=".concat(query.id)
      }, {
        id: 1,
        text: '互動問卷',
        path: top == self ? "/customer/data-card/questionnaire?id=".concat(query.id) : "/data-card/questionnaire?id=".concat(query.id)
      }],
      brand: isCome.value ? mukiConst.brand.KB : mukiConst.brand.MJ,
      brands: [{
        name: '美加',
        value: mukiConst.brand.MJ
      }, {
        name: '康博',
        value: mukiConst.brand.KB
      }]
    });

    if (router.currentRoute.value.name.indexOf('DataCardQuestionnaire') > -1) {
      cData.tabbarActiveItem = 1;
    }

    function onTabbarSwitch(evt) {
      if (evt.path) {
        router.push(evt.path);
      }
    } // 儲存instance


    function onInit(evt) {
      if (router.currentRoute.value.name.indexOf('DataCardBasicInfo') > -1) {
        cData.basicInfo = evt;
      }

      if (router.currentRoute.value.name.indexOf('DataCardQuestionnaire') > -1) {
        cData.questionnaire = evt;
      }
    } // 儲存


    function save() {
      if (router.currentRoute.value.name.indexOf('DataCardBasicInfo') > -1) {
        if (cData.basicInfo) {
          cData.basicInfo.save();
        }
      }

      if (router.currentRoute.value.name.indexOf('DataCardQuestionnaire') > -1) {
        if (cData.questionnaire) {
          cData.questionnaire.save();
        }
      }
    }

    onMounted(function () {});
    return function (_ctx, _cache) {
      var _component_MSelect = __unplugin_components_0;

      var _component_Tabbar = __unplugin_components_1;

      var _component_BaseButton = __unplugin_components_2;

      var _component_BRow = __unplugin_components_3;

      var _component_BaseSpacer = __unplugin_components_4;

      var _component_router_view = _resolveComponent("router-view");

      var _directive_width = _resolveDirective("width");

      var _directive_fill_height_fixed = _resolveDirective("fill-height-fixed");

      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["data-card-block", {
          'in-iframe': inIframe
        }])
      }, [_createVNode(_component_BRow, {
        mb: "",
        "gutter-x": "5px"
      }, {
        default: _withCtx(function () {
          return [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_MSelect, {
            modelValue: _unref(cData).brand,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _unref(cData).brand = $event;
            }),
            class: "target-select",
            options: _unref(cData).brands,
            h: "34px",
            fz: "12px"
          }, null, 8, ["modelValue", "options"]), [[_directive_width, 71]])])), [[_directive_width, 81]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_Tabbar, {
            modelValue: _unref(cData).tabbarActiveItem,
            "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
              return _unref(cData).tabbarActiveItem = $event;
            }), _cache[2] || (_cache[2] = function ($event) {
              return onTabbarSwitch($event);
            })],
            list: _unref(cData).tabbarList
          }, null, 8, ["modelValue", "list"])])), [[_directive_width, 158]]), _createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
            title: "儲存",
            variant: "purple",
            h: "35px",
            radius: "5px",
            fz: "15px",
            inline: "",
            onClick: _cache[3] || (_cache[3] = function ($event) {
              return save();
            })
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(" 儲存 ")];
            }),
            _: 1
          })), [[_directive_width, 54]])])];
        }),
        _: 1
      }), _createVNode(_component_BaseSpacer, {
        h: "10px"
      }), _createVNode(_component_router_view, {
        brand: _unref(cData).brand,
        "cs-id": _unref(query).id,
        onInit: onInit
      }, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.path
          }))], 1024))];
        }),
        _: 1
      }, 8, ["brand", "cs-id"])], 2)), [[_directive_fill_height_fixed, {
        minus: 65
      }]]);
    };
  }
};